<template>
<FormWrapper :is-loading="isLoading">
    <PageTitle title="Add Shift Change" slot="title" link="/helpContent/Change Shift" />
    <ValidationObserver ref="validator">
        <FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <FormSelect label="Staff Name" :items="staffList" item-name="Name" item-value="id" v-model="form.selectedStaff" rules="required" />
                </div>
                <div class="col-sm-4">
                    <FormSelect label="Shift" :items="shiftList" item-name="Name" item-value="id" v-model="form.selectedShift" rules="required" />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <inputDate label="Effective From" v-model="form.date" rules="required" />
                </div>
                <div class="col-sm-4">
                <InputTextArea label="Notes" rows="3" :maxlength="5000" v-model="form.Notes" rules="required" />
                 </div>
            </FormRow>
            <FormRow>
        <div class="col-sm-4">
            <ButtonGroup>
                <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                <FormButton type="success" @click="sendData()">Save</FormButton>
            </ButtonGroup>
        </div>
            </FormRow>
        </FormRow>
    </ValidationObserver>
    <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ showMessage.message }}
        <div slot="footer">
                <!--sagar add footer in popup so that page redirect to listview  -->
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup title="Information" type="info" :value="infoMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ infoMessage.message }}
        <div slot="footer">
                <!--sagar add footer in popup so that page redirect to listview  -->
        <FormButton
          @click="
            () => {
                (infoMessage.isVisible = false)
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
</FormWrapper>
</template>
<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {changeStaffShift, getAllStaffByHRorManager, getAllShift} from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import {ValidationObserver} from "vee-validate"
export default {
    name: "add",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
        InputDate,
        FormWrapper,
        PageTitle,
        ValidationObserver,
    },
    data() {
        return {
            showMessage: {
                isVisible: false,
            },
            infoMessage: {
                isVisible: false,
            },
            shiftList: [],      
            form: {
                date: "",
                startTime: "",
                endTime: "",
                Notes: "",
                selectedStaff:null,
                reasonId: null,
                selectedShift: null,
            },
            staffList: [],
            reasonList: [],
        };
    },
    computed: {
        userData() {
            return this.$store.state.user.user;
        },
    },
    watch: {},
    created() {
      this.getAllList()

    },
    methods: {
      async  getAllList(){
            await getAllStaffByHRorManager().then((res)=>{
            this.staffList=res.data
            this.form.selectedStaff=this.userData.user_id;
            });
            
            getAllShift().then((res) => {
           this.shiftList = res.data;
      });

          },
        sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                          StaffId: this.form.selectedStaff, //sagar changes send staffId instead of loginUser id 
                          ShiftId: this.form.selectedShift,
                          EffectiveFrom: this.form.date,
                          Notes: this.form.Notes,
                        };
                        changeStaffShift(data).then((res)=>{
                            if(res.data.status===1){
                                this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data.message;
                            }else{

                            this.infoMessage.isVisible=true;
                            this.infoMessage.message=res.data.message;
                            }
                       
                        })
                    }
                })
            },
    },
};
</script>

<style lang="scss">
.popupRow {
    padding: 5px 0 15px;
}

.pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
}

.paginationText {
    margin: 0 20px;
}

.paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
        box-shadow 200ms ease;
}

.paginationButton:hover {
    background: #57a3f3;
    color: #fff;

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
            0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
}

.filter {
    margin: -10px 0 10px;
}

.filterItem {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.filterItemCheckbox {
    flex-basis: auto;
    align-self: flex-start;
    margin-top: 13px;
}
</style>
